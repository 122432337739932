<template>
  <div class="privacyPolicy">
    <div class="privacyPolicy-content">
      <h1>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ з обробки та захисту персональних даних</h1>
      <p>Сервісом - АВТОМАТИЗОВАНА СИСТЕМА ОЦІНКИ КОМПЕТЕНТНОСТІ МОРЯКА Порталу «CREW»</p>

      <h2>ЗАГАЛЬНІ ПОЛОЖЕННЯ</h2>
      <p>Цією Політикою конфіденційності визначено загальні вимоги до обробки та захисту Персональних даних суб’єктів персональних даних, що обробляються із застосуванням автоматизованих засобів Сервісу - АВТОМАТИЗОВАНА СИСТЕМА ОЦІНКИ КОМПЕТЕНТНОСТІ МОРЯКА Порталу «CREW» (далі за текстом - Сервіс), а також Персональних даних, що містяться в базі даних Сервісу чи призначені до внесення до баз даних із застосуванням автоматизованих засобів.</p>
      <p>З метою забезпечення належного доступу та використання Сервісу та його додатків, Сервіс здійснює збір та обробку Персональних даних Користувачів.</p>
      <p>Сервіс виступає в якості власника та розпорядника Персональних даних Користувачів в залежності від того, за яких обставин було зібрано такі дані.</p>
      <p>Сервіс не збирає і не здійснює обробку Персональних даних, наданих особами віком до 16 років.</p>
      <p>Сервіс не збирає і не здійснює обробку таких категорій Персональних даних як дані про расове або етнічне походження, політичні, релігійні або світоглядні переконання, членство в політичних партіях та професійних спілках, засудження до кримінального покарання, а також даних, що статевого життя, біометричних або генетичних даних Користувачів.</p>
      <p>Сервіс зобов’язується забезпечити вжиття усіх доступних заходів захисту Персональних даних Користувачів із врахуванням положень чинного законодавства щодо захисту персональних даних та особливості сфери діяльності Сервіс.</p>

      <h2>ТЕРМІНИ ТА ВИЗНАЧЕННЯ</h2>
      <p>У цьому документі нижченаведені терміни вживаються в такому значенні:</p>
      <ul>
        <li>база персональних даних - іменована сукупність упорядкованих персональних даних в електронній формі та/або у формі картотек персональних даних;</li>
        <li>володілець персональних даних – юридична особа, яка визначає мету обробки персональних даних, встановлює склад цих даних та процедури їх обробки, якщо інше не визначено законом;</li>
        <li>згода суб’єкта персональних даних - добровільне волевиявлення фізичної особи (за умови її поінформованості) щодо надання дозволу на обробку її персональних даних відповідно до сформульованої мети їх обробки, висловлене у письмовій формі або у формі, що дає змогу зробити висновок про надання згоди.</li>
        <li>знеособлення персональних даних - вилучення відомостей, які дають змогу прямо чи опосередковано ідентифікувати особу;</li>
        <li>база даних - будь-які структуровані персональні дані, доступні за визначеними критеріями, незалежно від того, чи такі дані централізовані, децентралізовані або розділені за функціональними чи географічними принципами;</li>
        <li>обробка персональних даних - будь-яка дія або сукупність дій, таких як збирання, реєстрація, накопичення, зберігання, адаптування, зміна, поновлення, використання і поширення (розповсюдження, реалізація, передача), знеособлення, знищення персональних даних, у тому числі з використанням інформаційних (автоматизованих) систем;</li>
        <li>одержувач - фізична чи юридична особа, якій надаються персональні дані, у тому числі третя особа;</li>
        <li>персональні дані - відомості чи сукупність відомостей про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована;</li>
        <li>розпорядник персональних даних - юридична особа, якій володільцем персональних даних або законом надано право обробляти ці дані від імені володільця;</li>
        <li>суб’єкт персональних даних - фізична особа, персональні дані якої обробляються;</li>
        <li>третя особа - будь-яка особа, за винятком суб’єкта персональних даних, володільця чи розпорядника персональних даних, якій володільцем чи розпорядником персональних даних здійснюється передача персональних даних.</li>
        <li>Користувач – фізична або юридична особа, яка уклала Ліцензійний договір на право користування Сервісом та зареєструвалася на Сервісі у спосіб, визначений цим Договором.</li>
      </ul>

      <h2>СКЛАД , МЕТА ТА ПРАВОВІ ПІДСТАВИ ЗБОРУ ТА ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ</h2>
      <p>В залежності від того, яким чином Користувачі використовують Сервіс, а також від того, використання яких функцій Сервісу запитується, обсяг, спосіб, мета та правові підстави збору та обробки Персональних даних можуть змінюватися.</p>
      <p>Сервіс здійснює збір та обробку наступних категорій Персональних даних Користувачів:</p>
      <ul>
        <li>прізвище, ім’я, по-батькові;</li>
        <li>реєстраційні дані юридичної особи, включаючи дозволи, ліцензії та інші документи, що стосуються виду її діяльності;</li>
        <li>паспортні дані;</li>
        <li>реєстраційний номер облікової картки платника податків;</li>
        <li>особисті відомості (вік, стать, тощо);</li>
        <li>місце проживання за державною реєстрацією та/або фактичне місце проживання;</li>
        <li>професія, спеціальність, кваліфікація;</li>
        <li>електронні ідентифікаційні дані (IP-адреса, телефони, адреси електронної пошти, та інші);</li>
        <li>запис зображень (фото, відео; звукозапис);</li>
        <li>дані входу Користувачів на Веб-сайт/у Мобільні додатки Порталу (логіни, паролі);</li>
        <li>файли “cookies”, технічні дані для Веб-сайтів/Мобільних додатків.</li>
      </ul>

      <p>По відношенню до персональних даних щодо банківських платіжних карток (ім’я власника, номер, термін дії та CVV код) що здійснюються за допомогою програмних засобів Сервіс виступає винятково як володілець персональних даних, визначаючи мету та способи обробки персональних даних. З метою обробки таких категорій персональних даних Сервіс залучає спеціалізованих провайдерів платіжних послуг, які виступають у ролі розпорядників персональних даних, які мають винятковий доступ до обробки таких персональних даних.</p>
      <p>Сервіс також збирає анонімізовані дані, які не є персональними даними (Сервіс не може самостійно здійснити ідентифікацію Користувачів). Збір та обробка анонімізованих даних відбувається у консолідованому вигляді і використовується для проведення маркетингових досліджень.</p>
      <p>Сервіс має право отримувати та обробляти персональні дані з відкритих реєстрів та на основі запитів та/або прямого доступу до баз даних і будь-яких реєстрів, в тому числі, але не обмежуючись, на підставі угод про обмін інформацією між Сервісом та державними органами, організаціями, підприємствами будь-якої форми власності з метою формування бази даних Сервісу та надання можливості Користувачу максимального користування функціоналом Сервісу.</p>
      <p>Сервіс здійснює збір та обробку Персональних даних Користувачів Сервісу на таких правових підставах:</p>
      <ul>
        <li>згода на збір та обробку персональних даних;</li>
        <li>виконання договірних зобов’язань Сервісу;</li>
        <li>виконання вимог законодавства, які поширюються на Сервіс;</li>
        <li>законний інтерес Сервісу.</li>
      </ul>

      <p>Сервіс здійснює збір та обробку Персональних даних Користувачів Сервісу відповідно до такої мети:</p>
      <ul>
        <li>створення облікового запису на Веб-сайтах/у Мобільних додатках Сервісу;</li>
        <li>надання доступу Користувачів до Веб-сайтів/у Мобільні додатки Сервісу;</li>
        <li>замовлення та надання Користувачам відповідного функціоналу Сервісу;</li>
        <li>надання відповідей на пошукові та інформаційні запити Користувачів Сервісу;</li>
        <li>управління підпискою на інформаційну розсилку щодо функціоналу Сервісу;</li>
        <li>використання форм зворотного зв’язку на Веб-сайтах/у Мобільних додатках Сервісу;</li>
        <li>забезпечення обміну інформацією із Веб-сайтів/Мобільних додатків Сервісу;</li>
        <li>проведення маркетингових досліджень та аналізу тенденцій для покращення роботи Сервісу;</li>
        <li>покращення роботи Веб-сайтів/Мобільних додатків Сервісу;</li>
        <li>Надання Користувачами Персональних даних необхідне для користування функціоналом Веб-сайту/Мобільного додатку Сервісу.</li>
      </ul>

      <h2>ПРАВА КОРИСТУВАЧІВ СЕРВІСУ ЩОДО ПЕРСОНАЛЬНИХ ДАНИХ</h2>
      <p>Користувачі Сервісу мають право:</p>
      <ul>
        <li>на доступ до своїх Персональних даних або даних третіх осіб, які надали так згоду;</li>
        <li>на внесення змін до Персональних даних;</li>
        <li>на отримання інформації щодо способів обробки Персональних даних;</li>
        <li>на інформування про порушення цілісності Персональних даних;</li>
        <li>вимагати передачу своїх Персональних даних третім особам в запитуваному Користувачами обсязі;</li>
        <li>на відкликання згоди на збір та обробку Персональних даних;</li>
        <li>на призупинення обробки Персональних даних;</li>
        <li>вимагати видалення Персональних даних.</li>
      </ul>

      <h2>ПЕРЕДАЧА ПЕРСОНАЛЬНИХ ДАНИХ ТРЕТІМ ОСОБАМ</h2>
      <p>Сервіс здійснює передачу Персональних даних третім особам (в тому числі, і транскордонну) винятково в тому обсязі, в якому це необхідно для виконання мети збору та обробки Персональних даних, відносно яких такий збір та обробка були попередньо здійснені безпосередньо Сервісом.</p>
      <p>Для належного надання доступу до відповідних функцій Сервісу, а також покращення функціоналу Сервіс може здійснювати передачу Персональних даних таким особам:</p>
      <ul>
        <li>державні органи, органи влади, підприємства, установи та організації, що належать до сфери їх управління, діяльність яких пов’язана із сферою транспортного сектору економіки країни;</li>
        <li>організації, що займаються наданням послуг, в тому числі посередницьких, в сфері випробування, діагностики, сертифікації, переобладнання, технічної експлуатації колісних транспортних засобів, пасажирських та вантажних перевезень; перевезень небезпечних вантажів; безпеки на транспорті</li>
        <li>інші юридичні особи за рішенням уповноваженого на прийняття такого рішення органу або надання окремої згоди суб’єктом персональних даних такій юридичній та/або фізичній особі;</li>
        <li>учасники/члени платіжних систем в Україні, що забезпечують авторизацію та проведення суб’єктами персональних даних переказу коштів, та яким персональні дані, що обробляються, передаються для забезпечення проведення такого переказу;</li>
        <li>інші юридичні та фізичні особи, що законно звертаються із запитом щодо доступу до персональних даних, що обробляються.</li>
      </ul>

      <p>Персональні дані, що обробляються, передаються третім особам за згодою суб’єкта персональних даних. Передача персональних даних, що обробляються, третім особам без згоди суб’єкта персональних даних або уповноваженої ним особи дозволяється у випадках, визначених законом, і лише (якщо це необхідно) в інтересах національної безпеки, економічного добробуту та прав людини.</p>

      <h2>СТРОКИ ЗБЕРІГАННЯ ПЕРСОНАЛЬНИХ ДАНИХ</h2>
      <p>Строк зберігання персональних даних не більший, ніж це необхідно відповідно до їх законного призначення та мети їх обробки, якщо інше не передбачено законодавством у сфері архівної справи та діловодства.</p>
      <p>Сервіс здійснює обробку кожної із категорій Персональних даних, протягом періоду, необхідного для виконання кожної із вищевказаної мети. У будь-якому випадку Сервіс має право зберігати Персональні дані протягом періоду, необхідного для виконання зобов’язань перед Користувачами, а також для виконання зобов’язань, передбачених чинним законодавством.</p>

      <h2>ЗАХИСТ ПЕРСОНАЛЬНИХ ДАНИХ</h2>
      <p>Сервіс вживає заходів щодо забезпечення захисту персональних даних на всіх етапах їх обробки, у тому числі за допомогою організаційних та технічних заходів. Захист персональних даних передбачає заходи, спрямовані на запобігання їх випадкових втрати або знищення, незаконної обробки, у тому числі незаконного знищення чи доступу до персональних даних. З метою забезпечення безпеки обробки персональних даних вживаються спеціальні технічні заходи захисту, у тому числі щодо виключення несанкціонованого доступу до персональних даних, що обробляються та роботі технічного та програмного комплексу, за допомогою якого здійснюється обробка персональних даних.</p>
      <p>Здійснюючи передачу Персональних даних Користувачів на користь третіх осіб Сервіс вживає всіх доступних заходів правового, організаційного та технічного характеру для гарантування захисту Персональних даних.</p>

      <h2>ІНШІ ПОЛОЖЕННЯ</h2>
      <p>Обробка персональних даних здійснюється Сервісом лише за згодою суб’єкта персональних даних, за винятком тих випадків, коли така згода не вимагається Законом. Згода суб’єкта на обробку його персональних даних повинна бути добровільною та інформованою. Згода може надається суб’єктом в електронній формі, що дає змогу зробити висновок про її надання.</p>
      <p>Сервіс, крім випадків, передбачених законодавством України, повідомляє суб’єкта персональних даних про склад і зміст персональних даних, що збираються, його права, визначені Законом, мету збору персональних даних та третіх осіб, яким передаються його персональні дані - під час проходження реєстрації на Сервісі. В інших випадках, коли персональні дані Користувача надходять до Сервісу від третіх осіб, останні гарантують що такі дані отримані та обробляються відповідно до згоди суб’єкта персональних даних з урахуванням вимог п. 3 ст. 14 Закону України «Про захист персональних даних».</p>
      <p>У разі виявлення відомостей про особу, які не відповідають дійсності, такі відомості мають бути невідкладно змінені або знищені.</p>
      <p>З приводу будь-яких питань, які стосуються Персональних даних, Користувачі можуть направити запит чи скаргу представнику Сервісу з питань захисту даних на електронну адресу support@explorer.od.ua</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'privacyPolicy', access: false })
  }
}
</script>
